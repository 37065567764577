//= require slick-carousel/slick/slick.js

$(function() {
  var didResize = true,
      $zooms = $('.zoom__items');

  function responsivelyCreateSlider() {
    var mobile = $('.zoom__navigation').is(':visible');

    $zooms.each(function() {
      var $this = $(this);

      if (mobile && !$this.data('slider')) {
        $this.slick({
          infinite: true,
          // adaptiveHeight: true,
          dots: true,
          dotsClass: 'zoom__dots',
          prevArrow: $this.siblings('.js-zoom-prev'),
          nextArrow: $this.siblings('.js-zoom-next')
        });

        $this.data('slider', true);
      }
      else if(!mobile && $this.data('slider')) {
        $this.slick('unslick');
        $this.data('slider', false);
      }
    })
  }

  responsivelyCreateSlider();

  $window.on('resize', function () {
    didResize = true;
  });

  (function loop() {
    requestAnimationFrame(loop);

    if (didResize) {
      responsivelyCreateSlider();
      didResize = false;
    }
  })();
});