//= require svg4everybody/dist/svg4everybody.js

var $window = $(window),
  $document = $(document),
  $html = $('html'),
  $body = $('body');

svg4everybody();

//= require app/_form.js
//= require app/_news.js
//= require app/_overlay.js
//= require app/_sticky.js
//= require app/_tabs.js
//= require app/_zoom.js

$('.u-gimmick-top:not(:first-child)').prev().addClass('u-gimmick-after');
