;( function($, $window, $document) {
  'use strict';

  var selector        = '#header',
    class_hidden      = 'with-header-hidden',
    class_fixed       = 'with-header-fixed',
    fixed_offset      = 50,
    throttle_timeout  = 500,
    $body             = $('body'),
    $element          = $(selector);

  if (!$element.length) return true;

  var  w_height       = 0,
    w_scroll_current  = 0,
    w_scroll_before   = 0,
    w_scroll_diff     = 0,
    d_height          = 0,
    detection_margin  = null,

    throttle = function(delay, fn) {
      var last, defer_timer;
      return function() {
        var context = this, args = arguments, now = +new Date;
        if (last && now < last + delay) {
          clearTimeout(defer_timer);
          defer_timer = setTimeout(function(){
            last = now;
            fn.apply(context, args);
          }, delay);
        }
        else {
          last = now;
          fn.apply(context, args);
        }
      };
    };

  $window.on('scroll', function() {
    d_height          = $document.height();
    w_height          = $window.height();
    w_scroll_current  = $window.scrollTop();
    w_scroll_diff     = w_scroll_before - w_scroll_current;
    detection_margin  = $element.height() / 2;

    $body.toggleClass(class_fixed, w_scroll_current > fixed_offset); // toggles "narrow" classname

    throttle(throttle_timeout, function() {
      if (w_scroll_current <= 0) { // scrolled to the very top; element sticks to the top
        $body.removeClass(class_hidden);
      }
      else if (w_scroll_diff > 0 && $body.hasClass(class_hidden)) { // scrolled up; element slides in
        $body.removeClass(class_hidden);
      }
      else if (w_scroll_diff < 0) { // scrolled down
        if (w_scroll_current +  w_height >= d_height && $body.hasClass(class_hidden)) { // scrolled to the very bottom; element slides in
          $body.removeClass(class_hidden);
        }
        else if (w_scroll_current > detection_margin) { // scrolled down; element slides out
          $body.addClass(class_hidden);
        }
      }
    })();

    w_scroll_before = w_scroll_current;
  }).trigger('scroll');

})(jQuery, $window, $document);