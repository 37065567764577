$(function() {
  var didResize     = true,
      duration      = 3000,
      progressTheme = null;
      mobile        = $('.header__item-contact-icon').is(':visible');

  function animateToItem($tabs, item_index) {
    var $progress = $tabs.find('.js-tabs-progress'),
        $items    = $tabs.find('.js-tabs-item');

    $current_item = $items.filter(':eq(' + item_index + ')');
    setCurrentItem($current_item, true);

    if (progressTheme) $progress.removeClass(progressTheme);
    $progress.addClass($current_item.data('theme-class'));
    
    $tabs.data('progress-time', Math.floor(Date.now()));
  }

  function nextItem($tabs) {
    var $items = $tabs.find('.js-tabs-item');

    if ($tabs.data('current-item')) {
      var next_item = parseInt($tabs.data('current-item')) + 1;
      if (next_item > $items.length - 1) {
        next_item = 0;
      }
    }
    else {
      var next_item = 0;
    }

    animateToItem($tabs, next_item);
  }

  function resumeItem($tabs) {
    var $pausesAt = $tabs.data('progress-time-pause');
    if ($pausesAt) {
      var delta = Math.floor(Date.now()) - $pausesAt;
      $tabs.data('progress-time', $tabs.data('progress-time-backup') + delta);
    }
  }

  function stopCurrentItem($tabs) {
    $tabs.data('progress-time-backup', $tabs.data('progress-time'));
    $tabs.data('progress-time-pause', Math.floor(Date.now()));
    $tabs.data('progress-time', null);
  }

  function setCurrentItem($current_item, scroll) {
    var $tabs = $current_item.closest('.js-tabs');
    $tabs.data('current-item', String($tabs.find('.js-tabs-item').index($current_item)));

    $('#' + $current_item.data('tab-id')).removeClass('tabs__section--invisible').siblings().addClass('tabs__section--invisible');

    if (scroll) {
      var left = $current_item.parent().children().index($current_item.get(0)) * $current_item.outerWidth(true);
      $current_item.parent().animate({scrollLeft: left}, 300);
    }

    $current_item
      .addClass('tabs__item--active')
      .siblings().removeClass('tabs__item--active');
  }

  var chrome = /chrome/i.test(navigator.userAgent);
  function refreshHeight($tabs) {
    var maxh = 0;
    var $contents = $('#' + $tabs.find('.js-tabs-item:eq(0)').data('tab-id')).siblings().andSelf();
    $contents.each(function() {
      $(this).css('height', 'auto');
    });
    $contents.each(function() {
      var $this = $(this);
      var height = $this.outerHeight() + (chrome ? 16 : 0);
      if (height > maxh) maxh = height;
    });
    $contents.each(function() {
      $(this).css('height', maxh + 'px');
    });
  }

  // function doProgress($tabs) {
  //   var startTime = $tabs.data('progress-time');
  //
  //   if (!startTime) return;
  //
  //   var currentTime   = Math.floor(Date.now()),
  //     delta           = currentTime - startTime,
  //     progression     = Math.round(delta * 10000 / duration) / 10000;
  //
  //   $tabs.find('.js-tabs-progress').css({
  //     "-webkit-transform": "scaleX(" + progression + ")",
  //     "-ms-transform": "scaleX(" + progression + ")",
  //     "transform": "scaleX(" + progression + ")"
  //   });
  //
  //   if(delta >= duration) {
  //     nextItem($tabs);
  //   }
  // }

  $('.js-tabs').each(function() {
    var $tabs = $(this);
    refreshHeight($tabs);
    nextItem($tabs);
  });

  $('.js-tabs-item').on('click', function(e) {
    e.preventDefault();

    var $current_item = $(this);
    stopCurrentItem($current_item.closest('.js-tabs'));
    setCurrentItem($current_item, true);
  });

  // $('.js-tabs-item').on('mouseenter', function() {
  //   var $current_item = $(this);
  //   stopCurrentItem($current_item.closest('.js-tabs'));
  //   setCurrentItem($current_item, false);
  // }).on('mouseleave', function() {
  //   var $tabs = $(this).closest('.js-tabs');
  //   resumeItem($tabs);
  // });

  $window.on('resize', function () {
    didResize = true;
  });

  var timeout_resize = null;
  (function loop() {
    requestAnimationFrame(loop);

    // $('.js-tabs').each(function() {
    //   doProgress($(this));
    // });

    if (didResize) {
      clearTimeout(timeout_resize);
      timeout_resize = setTimeout(function() {
        $('.js-tabs').each(function() {
          refreshHeight($(this));
        });
      }, 200);
      didResize = false;
    }
  })();
});