$(function() {
  var hasFloatingClass = "has-floating",
      isFloatingClass = "is-floating";

  $('.js-floating-label').each(function(index, label) {
    var $label = $(label),
        $input = $('#' + $label.attr('for'));

    $label.addClass(hasFloatingClass);

    if(!$label.hasClass(isFloatingClass) && $input.val() != "" && typeof($input.val()) != 'undefined') {
      $label.addClass(isFloatingClass);
    }

    $label.on('click', function(event) {

      if($input.is('select')) {
        $input.trigger('click');
      }
    });

    $input.on('focusin', function(event) {

      if(!$label.hasClass(isFloatingClass)) {
        $label.addClass(isFloatingClass);
      }

    });

    $input.on('focusout', function(event) {

      if($input.val() == "" || typeof($input.val()) == 'undefined') {
        $label.removeClass(isFloatingClass);
      }

    });
  });
});